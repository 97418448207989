import ckeditorconfig from "@/libs/ckeditorconfig";
export default [
  {
    key: "bannerId",
    initKey: "banner",
    label: "field.banner",
    type: "async-single-image",
    path: "brand-category",
    pw: 1000,
    ph: 500,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "thumbnailId",
    initKey: "thumbnail",
    label: "field.thumbnail",
    type: "async-single-image",
    path: "brand-category",
    pw: 960,
    ph: 870,
    fullWidth: true,
    disableUploadHistory: true,
    cols: 12,
  },
  {
    key: "nameEn",
    label: "field.nameEn",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "nameKm",
    label: "field.nameKm",
    rules: "required|max:100",
    type: "text",
  },
  {
    key: "sequenceOrder",
    label: "field.sequenceOrder",
    rules: "integer|min_value:1",
    type: "currency",
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    label: "breadcrumb.brandCategoryContentEn",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "descriptionEn",
    label: "field.descriptionEn",
    rules: "required|max:10000",
    type: "ck-editor",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },
  {
    key: "contactInformationEn",
    label: "field.contactInformationEn",
    rules: "required|max:10000",
    type: "ck-editor",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },
  {
    key: "metaTitleEn",
    label: "field.metaTitleEn",
    rules: "max:100",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionEn",
    label: "field.metaDescriptionEn",
    rules: "max:10000",
    type: "textarea",
    cols: 12,
  },
  {
    label: "breadcrumb.brandCategoryContentKm",
    icon: "FileTextIcon",
    type: "divider",
  },
  {
    key: "descriptionKm",
    label: "field.descriptionKm",
    rules: "required|max:10000",
    type: "ck-editor",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },
  {
    key: "contactInformationKm",
    label: "field.contactInformationKm",
    rules: "required|max:10000",
    type: "ck-editor",
    config:{...ckeditorconfig.editorConfig},
    cols: 12
  },
  {
    key: "metaTitleKm",
    label: "field.metaTitleKm",
    rules: "max:100",
    type: "text",
    cols: 6,
  },
  {
    key: "metaDescriptionKm",
    label: "field.metaDescriptionKm",
    rules: "max:10000",
    type: "textarea",
    cols: 12,
  },
  {
    key: "isEnable",
    label: "field.active",
    rules: "",
    type: "checkbox",
    cols: "auto",
  },
];
